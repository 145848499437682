// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cadastro-js": () => import("./../../../src/pages/cadastro.js" /* webpackChunkName: "component---src-pages-cadastro-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-redacao-nota-1000-js": () => import("./../../../src/pages/redacao-nota-1000.js" /* webpackChunkName: "component---src-pages-redacao-nota-1000-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */)
}

